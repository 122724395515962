
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch, Ref } from "vue-property-decorator";
import * as AcctService from "@/services/DAL/acctService";
import * as AssetService from '@/services/DAL/assetService';
import Common from "@/utils/common";
import formatters from "@/utils/formatters";
import ElementUI from "element-ui";
import uniqBy from 'lodash/uniqBy';
import alasql from 'alasql'; 
import * as XLSX from 'xlsx';
alasql['utils'].isBrowserify = false;
alasql['utils'].global.XLSX = XLSX;

declare function getStoredSecurityLevel(Id: number): number;
declare function tryParseInt(input: any, defaultValue: number): number;
@Component({})
export default class EntityJournalVouchers extends Vue {
@Ref() readonly refTableGL!: ElementUI.Table;
  //#region Private declarations for Services
  private _acctService: AcctService.AcctService;
  private _assetService: AssetService.AssetService;
  private common: Common;
  public formatters: formatters;

  //#endregion Private declarations for Services

  //#region Props
  @Prop({ required: true, type: Number }) readonly entityId: number;
  @Prop({ required: true, type: String }) readonly entityType: string;
  @Prop({ type: Number }) readonly ExcludeTransactionHeaderId: number;
  @Prop({ type: [String, Date] }) readonly asOf: string | Date;
  @Prop({ type: String }) readonly bspl: string;
  @Prop({ type: Boolean, default: true }) readonly specificEntityOnly: boolean;
  @Prop({ type: Object, default: () => ({}) }) readonly hide: Record<string, any>;
  @Prop({ type: Boolean, default: false }) readonly showAccountSummary: boolean;
  @Prop({ type: Boolean, default: false }) readonly showReapplyValuations: boolean;
  @Prop({ type: Boolean, default: false }) readonly excludeAllTransactionsSameDateAsExcludedHeaderId: boolean;
  @Prop({ type: Boolean, default: false }) readonly showPendingOption: boolean;
  @Prop({ type: Boolean, default: false }) readonly useEntityNotProvenance: boolean;
  @Prop({ type: Boolean, default: true }) readonly showRunningColumns: boolean;
  @Prop({ type: Boolean, default: false }) readonly enableStatistics: boolean;
  
  
  //#endregion Props

  //#region Data
  securityLevel_: number = null;
  loading = false;
  entityGeneralLedger = []  as AcctService.EntityGeneralLedger[];
  tableHeight: string = null;
  loadingReapplyValuations = false;
  includePending = false;
  dateFilterFormat: string = 'date';

  showStatisticFMV = false;
  showStatisticBasis = false;
  showStatisticInvested = false;
  showStatisticCalled = false;
  showStatisticReturned = false;

  stats = ['FMV', 'Basis', 'Invested', 'Called', 'Returned'];
  statsSelected = [];
  isIndeterminateStats = false;
  checkAllStats = false;

  //#endregion Data

  //#region Lifecycle
  async created() {
    this._acctService = new AcctService.AcctService();
    this._assetService = new AssetService.AssetService();
    this.formatters = new formatters();
    this.common = new Common();
    this.securityLevel_ = tryParseInt(
      getStoredSecurityLevel(this.$namedKey.SecurityView.ManageAssets),
      0
    );
    this.fetchJVs();
  }
  async mounted() {
      this.setTableHeight();
  }
  //#endregion Lifecycle
  //#region Watches

  @Watch('entityId')
    onChange_entityId(val: number, oldVal: number) {
        if (oldVal !== undefined) {
            this.fetchJVs();
        }
    }
  @Watch('includePending')
    onChange_includePending(val: number, oldVal: number) {
        if (oldVal !== undefined) {
            this.fetchJVs();
        }
    }
  @Watch('entityType')
    onChange_entityType(val: number, oldVal: number) {
        if (oldVal !== undefined) {
            this.fetchJVs();
        }
    }
@Watch('useEntityNotProvenance')
    onChange_useEntityNotProvenance(val: number, oldVal: number) {
        if (oldVal !== undefined) {
            this.fetchJVs();
        }
    }
  @Watch('asOf')
    onChange_asOf(val: string | Date, oldVal: string | Date) {
        if (oldVal !== undefined) {
            this.fetchJVs();
        }
    }
  @Watch('$attrs', {deep: true})
    async onChange_attr(val: any, oldVal: any) {
        if (oldVal['max-height'] !== val['max-height']) {
            await this.setTableHeight();
        }
    }

  //#endregion Watches
   get runningBalances(){
    if (this.refTableGL){
      const gl = ((this.refTableGL as any).tableData as AcctService.EntityGeneralLedger[]);
      const data = gl.map(gl => ({
         BSPL: gl.BSPL, 
         Attribution: gl.Attribution
         }));
      const sums = [
        ...data.reduce(
          (map, item) => {
            const { BSPL: key, Attribution } = item;
            const prev = map.get(key);
            
            if(prev) {
              prev.Attribution += Attribution
            } else {
              map.set(key, Object.assign({}, item))
            }
            
            return map
          },
          new Map()
        ).values()
      ]
      return sums;
    }
   }

   get valuationCount(){
    if (this.entityGeneralLedger && this.entityGeneralLedger.length){
        return this.entityGeneralLedger.filter((gl) => gl.SourceEntityType === 'Valuation').length;
    }
    else return 0;
   }

  get accountSummary(){
    if (this.showAccountSummary && this.refTableGL){
      const gl = ((this.refTableGL as any).tableData as AcctService.EntityGeneralLedger[]);
      const data = gl.map(gl => ({
         AccountNamePath: gl.AccountNamePath, 
         NormalBalance: gl.NormalBalance, 
         Attribution: gl.Attribution,
         AttributionSummaryDebit: gl.AttributionSummaryDebit || 0,
         AttributionSummaryCredit: gl.AttributionSummaryCredit || 0,
         AttributionSummaryDebitNetABS: 0,
         AttributionSummaryCreditNetABS: 0
         }));
      const sums = [
        ...data.reduce(
          (map, item) => {
            const { AccountNamePath: key, Attribution, AttributionSummaryCredit, AttributionSummaryDebit, NormalBalance } = item;
            const prev = map.get(key);
            
            if(prev) {
              prev.Attribution += Attribution;
              prev.AttributionSummaryCredit += AttributionSummaryCredit;
              prev.AttributionSummaryDebit += AttributionSummaryDebit;
              if (prev.AttributionSummaryDebit + prev.AttributionSummaryCredit == 0 ){
                prev.AttributionSummaryCreditNetABS = 0;
                prev.AttributionSummaryDebitNetABS = 0;
              }
              else if ((prev.AttributionSummaryDebit + prev.AttributionSummaryCredit > 0 && NormalBalance == 'D')
                ||(prev.AttributionSummaryDebit + prev.AttributionSummaryCredit < 0 && NormalBalance == 'C')){
                prev.AttributionSummaryDebitNetABS = Math.abs(prev.AttributionSummaryDebit + prev.AttributionSummaryCredit);
                prev.AttributionSummaryCreditNetABS = 0;
                }
              else if ((prev.AttributionSummaryDebit + prev.AttributionSummaryCredit > 0 && NormalBalance == 'C')
                ||(prev.AttributionSummaryDebit + prev.AttributionSummaryCredit < 0 && NormalBalance == 'D')){
                prev.AttributionSummaryCreditNetABS = Math.abs(prev.AttributionSummaryDebit + prev.AttributionSummaryCredit);
                prev.AttributionSummaryDebitNetABS = 0
                }

            } else { // still need to set the net abs values
                if (item.AttributionSummaryDebit + item.AttributionSummaryCredit == 0 ){
                item.AttributionSummaryCreditNetABS = 0;
                item.AttributionSummaryDebitNetABS = 0;
              }
              else if ((item.AttributionSummaryDebit + item.AttributionSummaryCredit > 0 && item.NormalBalance == 'D')
                ||(item.AttributionSummaryDebit + item.AttributionSummaryCredit < 0 && item.NormalBalance == 'C')){
                item.AttributionSummaryDebitNetABS = Math.abs(item.AttributionSummaryDebit + item.AttributionSummaryCredit);
                item.AttributionSummaryCreditNetABS = 0;
                }
              else if ((item.AttributionSummaryDebit + item.AttributionSummaryCredit > 0 && item.NormalBalance == 'C')
                ||(item.AttributionSummaryDebit + item.AttributionSummaryCredit < 0 && item.NormalBalance == 'D')){
                item.AttributionSummaryCreditNetABS = Math.abs(item.AttributionSummaryDebit + item.AttributionSummaryCredit);
                item.AttributionSummaryDebitNetABS = 0
                }
              map.set(key, Object.assign({}, item));
            }
            
            return map;
          },
          new Map()
        ).values()
      ]
      return sums;
    }
  }
  get dateFilterFunction(): Object{
    if (this.dateFilterFormat == 'date'){
        return this.shortDate;
    }
    if (this.dateFilterFormat == 'month'){
        return this.month;
    }
    else if (this.dateFilterFormat == 'year'){
        return this.year;
    }
       
  }


//#region Methods
    setTableHeight() {
        if (this.$attrs['max-height']){
            this.tableHeight = this.$attrs['max-height'];
            return;
        }
        this.tableHeight = (window.innerHeight - 50 - 40 - 50).toString() + 'px'; //50: Navbar; 40: Breadcrumb; 50: footer
        //tableHeight.value = root.value.clientHeight - 0 - 28; //0: Selectors; 28: Filter w/ padding (ClientHeight is what is inside the router.  934 in DEV)
        this.refTableGL.doLayout();
    }

    public async fetchJVs(){
        this.loading = true;
        const params = {} as AcctService.GetEntityGeneralLedgerParameters;
        params.EntityType = this.entityType;
        params.EntityId = this.entityId;
        params.AsOf = this.asOf ? this.$dayjs(this.asOf).format('MM/DD/YYYY') : null;
        params.SpecificEntityOnly = this.specificEntityOnly;
        params.ExcludeAllTransactionsSameDateAsExcludedHeaderId = this.excludeAllTransactionsSameDateAsExcludedHeaderId;
        params.ExcludeTransactionHeaderId = this.ExcludeTransactionHeaderId;
        params.BSPL = this.bspl;
        params.IncludePending = this.includePending;
        params.UseEntityNotProvenance = this.useEntityNotProvenance;
        this.entityGeneralLedger = await this._acctService.GetEntityGeneralLedger(params);
        this.$emit('fetched', this.entityGeneralLedger);
        this.loading = false;
        if (this.entityGeneralLedger && this.entityGeneralLedger.length == 0 && this.entityType == 'Investment') this.checkIfReApplyingValuations();
        this.checkHide();
        await this.$nextTick();
        this.setTableHeight();
    }

    checkHide(){
        if (this.entityGeneralLedger && this.entityGeneralLedger.length){
            if (!this.hide || Object.keys(this.hide).length == 0 || !this.hide.Owner){
                const owners = this.entityGeneralLedger.map(gl => gl.OwnerEntityName);
                if (owners.every((val, i, arr) => val === arr[0]) || owners.every((val, i, arr) => val === null)){
                    this.hide.Owner = true;
                }                
            }
            if (!this.hide || Object.keys(this.hide).length == 0 || !this.hide.Transaction){
                const headers = this.entityGeneralLedger.map(gl => gl.HeaderDescription);
                if (headers.every((val, i, arr) => val === arr[0]) || headers.every((val, i, arr) => val === null)){
                    this.hide.Transaction = true;
                }
            }
        }
    }
    async checkIfReApplyingValuations(){
        if (this.entityType && this.entityType == 'Investment' && this.entityId){
            const parms = {} as AssetService.GetReApplyValuationStatusParameters;
            parms.InvestmentId = this.entityId;
            const status = await this._assetService.GetReApplyValuationStatus(parms);
            if (status && status.length == 1 && status[0].ReApplyCompleted == null){
                this.$alert(`No Accounting records are returned because there is currently a Reapply Valuations Process running.  It was initiated by ${status[0].CreateUser} at ${this.$dayjs(status[0].ReApplyStarted).local().format('L LT')}.`);
            }
            else {
                console.log('checked reapply valuations', status);
            }
        }
    }
    async reapplyValuations(){
        if (this.entityType && this.entityType == 'Investment' && this.entityId && this.valuationCount < 100){
            this.loadingReapplyValuations = true;
            const parms = {} as AssetService.ReapplyInvestmentValuationsParameters;
            parms.InvestmentId = this.entityId;
            await this._assetService.ReapplyInvestmentValuations(parms);
            await this.fetchJVs();
            this.loadingReapplyValuations = false;
        }
        else if (this.entityType && this.entityType == 'Investment' && this.entityId && this.valuationCount >= 100){
            try {
            const el = this;
            const conf: any = await this.$prompt(
            `Because of the number of valuations (${this.valuationCount}), the process will be run in the background and you will be notified by email when it is complete. `
            + `You can optionally enter a start date here.  All valuations on and after this date will be re-applied.  Or leave this blank and click OK to process all ${this.valuationCount} valuations.`,
            'On and After Date',
            {
                // MessageBoxData Type must be incorrectly defined, so declaring it as any
                confirmButtonText: "OK",
                showCancelButton: true,
                inputErrorMessage: 'Must be blank or a valid date',
                inputValidator: function (input) {
                    if (!input) return true;
                    else if (
                        input &&
                        el.$dayjs(input).isValid()
                    )
                        return true;
                    else return false;
                },
            }
            );
            
            this.loadingReapplyValuations = true;
            const parms = {} as AssetService.QueueReApplyValuationsParameters;
            parms.InvestmentId = this.entityId;
            parms.OnAndAfterDate = conf.value ? this.$dayjs(conf.value).format('MM/DD/YYYY') : null;
            await this._assetService.QueueReApplyValuations(parms);
            this.$notify.success('Re-apply valuations process started.  You will be notified by email when it is complete.');
            //await this.fetchJVs();
            this.entityGeneralLedger = [] as AcctService.EntityGeneralLedger[];
            this.$emit('fetched', this.entityGeneralLedger);
            this.loadingReapplyValuations = false;
            }
            catch{} //cancelled from prompt
        }
        else if (this.entityType && this.entityType == 'FixedIncome' && this.entityId){
            this.loadingReapplyValuations = true;
            const parms = {} as AssetService.ReapplyFixedIncomeValuationsParameters;
            parms.FixedIncomeId = this.entityId;
            await this._assetService.ReapplyFixedIncomeValuations(parms);
            await this.fetchJVs();
            this.loadingReapplyValuations = false;
        }
    }
    valuationsRowClassName({row, rowIndex}): string {
        if (row.SourceEntityType == 'Valuation' && this.loadingReapplyValuations){
            return 'warning-row';
        }
        return '';
    }
    month(date) {
        if (date === undefined || date === '' || date === null) return '';
        else return this.$dayjs(date).format('M/YYYY');
    }
    shortDate(date) {
        if (date === undefined || date === '' || date === null) return '';
        else return this.$dayjs(date).format('M/D/YY');
    }
    year(date) {
        if (date === undefined || date === '' || date === null) return '';
        else return this.$dayjs(date).format('YYYY');
    }

    filterHandler(value, row, column, prop, formatter?) {
        const property = prop ? prop : column['property'];
        return (formatter ? formatter(row[property]) : row[property]) === value;
    }
  
    filters(column, formatter?){ //formatter only supports shortDate or year currently
        const filters = this.entityGeneralLedger.map(function(list) {
            return {
                text: formatter ? formatter(list[column]) : list[column]
                , value: formatter ? formatter(list[column]) : list[column]
                };
        });
        const uniq = uniqBy(filters, 'value');
        return uniq;
    }
    
    getSummary(param) {
        
        const hides = Object.keys(this.hide);
        const left = hides.filter(key =>{
            return (key == 'BSPL' || key == 'Date' || key == 'Transaction' || key == 'Source' || key == 'Owner' );
        });
        const factor = left.length;
        const columnList = [6-factor, 7-factor];
        const totalLabel = ' ';
        const totalLabelIndex = 0;
        const formats = new Map();
        formats.set(6-factor, 'currency');
        formats.set(7-factor, 'currency');
        const showStats = [this.showStatisticFMV, this.showStatisticBasis, this.showStatisticInvested, this.showStatisticCalled, this.showStatisticReturned];
        for (let i = 0; i < showStats.length; i++){
            if (showStats[i]){
                let newColumnIndex = 0;
                if (columnList.length == 2) { // handle the 2 columsn in between the DR/CR and the start of the stats columns
                newColumnIndex = Math.max(...columnList) + 3;
                }
                else {
                newColumnIndex= Math.max(...columnList) + 1;
                }

                columnList.push(newColumnIndex);
                formats.set(newColumnIndex, "currency");
            }
        }
        const result = this.common.getSummaryArray(param, columnList, totalLabel, totalLabelIndex, formats);
        return result;
    }
    getSummaryAccountSummary(param) {
        const columnList = [1,2];
        const totalLabel = "NET Total";
        const totalLabelIndex = 0;
        const formats = new Map();
        formats.set(1, "currency");
        formats.set(2, "currency");
        const result = this.common.getSummaryArray(
        param,
        columnList,
        totalLabel,
        totalLabelIndex,
        formats
        );
        return result;
    }

    async exportItems(){
        if (this.entityGeneralLedger.length > 0) {
            var opts = [{ sheetid: this.entityId.toString(), header: true }];
            try {
                
                await alasql.promise(`SELECT * INTO XLSX("Accounting ${this.entityId.toString()}.xlsx",?) FROM ?`, [opts, [this.entityGeneralLedger]])
                this.$notify.success('Excel file exported.');
            }
            catch(err) {
                this.$notify.error(err.message);
            }
        }
    }

    runningTotal(index, column){
        if (index == undefined || index < 0 || !this.refTableGL) return 0;
        return ((this.refTableGL as any).tableData as AcctService.EntityGeneralLedger[])[index][column] + this.runningTotal(index-1, column);
    }

    handleCheckAllStatsChange(val) {
        this.statsSelected = val ? this.stats : [];
        this.isIndeterminateStats = false;
        this.handleCheckedStatsChange(this.statsSelected);
    }
    handleCheckedStatsChange(value) {
        const checkedCount = value.length;
        this.checkAllStats = checkedCount === this.stats.length;
        this.isIndeterminateStats = checkedCount > 0 && checkedCount < this.stats.length;
        this.showStatisticFMV = value.includes('FMV');
        this.showStatisticBasis = value.includes('Basis');
        this.showStatisticInvested = value.includes('Invested');
        this.showStatisticCalled = value.includes('Called');
        this.showStatisticReturned = value.includes('Returned');
    }    
  //#endregion Methods
}
