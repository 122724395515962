// TS API for Schema attachment
// Auto-generated 03/29/24 12:07


declare var smartData: any;
// output classes:
export class Attachment {
    Id: number=undefined;
    AttachmentId: number=undefined;
    FileId: string=undefined;
    DriveId: string=undefined;
    ClientId: number=undefined;
    ClientDisplay: string=undefined;
    Dated: string=undefined;
    Description: string=undefined;
    IngestUser: string=undefined;
    IngestDate: string=undefined;
    TypeId: number=undefined;
    StoreId: number=undefined;
    Store: string=undefined;
    Comment: string=undefined;
    EntityType: string=undefined;
    CategoryId: number=undefined;
    Type: string=undefined;
    Deletable: boolean=undefined;
    ShortDateUser: string=undefined;
    Maps: number=undefined;
    MappingNote: string=undefined;
    StoreCategoryId: number=undefined;
    APIPath: string = undefined;
}
export class Attachments {
    Id: number=undefined;
    AttachmentId: number=undefined;
    FileId: string=undefined;
    ClientId: number=undefined;
    Description: string=undefined;
    TypeId: number=undefined;
    Type: string=undefined;
    TypeEntityType: string=undefined;
    Dated: string=undefined;
    APIVersion: string=undefined;
    ItemPath: string=undefined;
    DriveItemsPath: string=undefined;
    FolderItemPath: string=undefined;
    Maps: number=undefined;
    LastModified: string=undefined;
    StoreId: number = undefined;
}
export class BulkCopy {
    Id: number=undefined;
    Name: string=undefined;
    CreateUser: string=undefined;
    CreateDate: string=undefined;
    DestinationDriveId: string=undefined;
    DestinationItemId: string=undefined;
    Completed: string=undefined;
    Action: string = undefined;
}
export class BulkCopyItems {
    Id: number=undefined;
    BulkCopyId: number=undefined;
    AttachmentId: number=undefined;
    EntityType: string=undefined;
    EntityId: number=undefined;
    Success: boolean=undefined;
    ActualSuccess: boolean=undefined;
    NewItemId: string=undefined;
    Message: string=undefined;
    FileName: string=undefined;
    CreateUser: string=undefined;
    CreateDate: string=undefined;
    DriveId: string=undefined;
    FileId: string=undefined;
    Description: string=undefined;
    Status: string=undefined;
    IsLoading: boolean=undefined;
    DestinationDriveId: string=undefined;
    DestinationParentId: string = undefined;
}
export class Categories {
    Id: number=undefined;
    Name: string=undefined;
    AllowClientNonSpecific: boolean = undefined;
}
export class EntitiesToMap {
    EntityId: number=undefined;
    Description: string=undefined;
    EntityType: string=undefined;
    Selectable: boolean=undefined;
    AlreadyMapped: boolean=undefined;
    Drillable: boolean=undefined;
    MappedDisplay: string=undefined;
    SelectedEntityId: number=undefined;
    SelectedEntityType: string = undefined;
}
export class Maps {
    MapId: number=undefined;
    Id: number=undefined;
    TypeId: number=undefined;
    AttachmentId: number=undefined;
    EntityId: number=undefined;
    EntityType: string=undefined;
    FileId: string=undefined;
    ClientId: number=undefined;
    Description: string=undefined;
    Comment: string=undefined;
    Dated: string=undefined;
    Type: string=undefined;
    TypeEntityType: string=undefined;
    LastModified: string=undefined;
    AttachmentType: string=undefined;
    Association: string=undefined;
    Client: string=undefined;
    Owner: string=undefined;
    APIVersion: string=undefined;
    BasePath: string=undefined;
    ParentEntityId: number=undefined;
    ParentEntityType: string=undefined;
    Store: string=undefined;
    AssociationUrl: string = undefined;
}
export class MapsForEntity {
    MapId: number=undefined;
    Id: number=undefined;
    TypeId: number=undefined;
    AttachmentId: number=undefined;
    EntityId: number=undefined;
    EntityType: string=undefined;
    FileId: string=undefined;
    ClientId: number=undefined;
    Description: string=undefined;
    Comment: string=undefined;
    Dated: string=undefined;
    Type: string=undefined;
    TypeEntityType: string=undefined;
    LastModified: string=undefined;
    AttachmentType: string=undefined;
    Association: string=undefined;
    Client: string=undefined;
    Owner: string=undefined;
    APIVersion: string=undefined;
    ItemPath: string=undefined;
    DriveItemsPath: string=undefined;
    FolderItemPath: string=undefined;
    ParentEntityId: number=undefined;
    ParentEntityType: string=undefined;
    Maps: number=undefined;
    RequiredDocumentType: number=undefined;
    RequiredDocumentCount: number=undefined;
    StoreId: number=undefined;
    UseExternalDocumentLinks: boolean = undefined;
}
export class RecentAttachments {
    Id: number=undefined;
    AttachmentId: number=undefined;
    FileId: string=undefined;
    ClientId: number=undefined;
    Description: string=undefined;
    TypeId: number=undefined;
    Type: string=undefined;
    TypeEntityType: string=undefined;
    Dated: string=undefined;
    APIVersion: string=undefined;
    ItemPath: string=undefined;
    DriveItemsPath: string=undefined;
    FolderItemPath: string=undefined;
    Maps: number=undefined;
    Client: string=undefined;
    Store: string=undefined;
    StoreId: number=undefined;
    LastModified: string = undefined;
}
export class Sites {
    Id: number=undefined;
    SharePointId: string=undefined;
    Name: string = undefined;
}
export class StoreAttachments {
    AttachmentId: number=undefined;
    FileId: string=undefined;
    ClientId: number=undefined;
    Description: string=undefined;
    TypeId: number=undefined;
    Type: string=undefined;
    TypeEntityType: string=undefined;
    Dated: string=undefined;
    IngestUser: string=undefined;
    IngestDate: string = undefined;
}
export class StoreCategories {
    Id: number=undefined;
    Name: string=undefined;
    AllowClientNonSpecific: boolean = undefined;
}
export class Stores {
    Id: number=undefined;
    Name: string=undefined;
    BasePath: string=undefined;
    Suffix: string=undefined;
    DetailSuffix: string=undefined;
    Type: string=undefined;
    ClientId: number=undefined;
    SharePointSiteId: string=undefined;
    SharePointListId: string=undefined;
    DisplayOrder: number=undefined;
    ListPath: string=undefined;
    RootPath: string=undefined;
    RootPathNoColon: string=undefined;
    DrivePath: string=undefined;
    ItemPath: string=undefined;
    DriveItemsPath: string=undefined;
    FolderItemPath: string=undefined;
    IsIngestionSource: boolean=undefined;
    AllowFileDelete: boolean=undefined;
    CategoryId: number=undefined;
    Folder: string=undefined;
    DriveId: string=undefined;
    StoreCategoryId: number=undefined;
    ItemId: string = undefined;
}
export class Types {
    Id: number=undefined;
    EntityType: string=undefined;
    Name: string=undefined;
    CreateUser: string=undefined;
    CreateDate: string=undefined;
    CategoryId: number=undefined;
    Category: string = undefined;
}

// Parameter interfaces:

export interface FixFileIdParameters {
    StoreId: number;
    eTag: string;
    FileId: string;
}

export interface GetAttachmentParameters {
    AttachmentId: number;
    FileId: string;
}

export interface GetAttachmentsParameters {
    AttachmentTypeId: number;
    EntityType: string;
    EntityId: number;
    StoreId: number;
    UnmappedOnly: boolean;
}

export interface GetBulkCopyItemsParameters {
    BulkCopyId: number;
    AlreadyExistsOnly: boolean;
}

export interface GetCategoriesParameters {
    EntityType: string;
}

export interface GetEntitiesToMapParameters {
    TypeId: number;
    SelectedEntityType: string;
    SelectedEntityId: number;
    ClientId: number;
    AttachmentId: number;
}

export interface GetMapsParameters {
    AttachmentId: number;
    FileId: string;
}

export interface GetMapsForEntityParameters {
    EntityType: string;
    EntityId: number;
}

export interface GetRecentAttachmentsParameters {
    Dummy: boolean;
    UnmappedOnly: boolean;
}

export interface GetStoreAttachmentsParameters {
    StoreId: number;
    FileId: string;
}

export interface GetStoreCategoriesParameters {
    StoreCategoryId: number;
}

export interface GetStoresParameters {
    Type: string;
    StoreId: number;
    ClientId: number;
    OnlyDefaultEntityStore: boolean;
    CategoryId: number;
    SourceDriveId: string;
    OnlyAPARStore: boolean;
    OnlyStageAPARStore: boolean;
    StoreCategoryId: number;
}

export interface GetTypesParameters {
    CategoryId: number;
    EntityType: string;
}

export class AttachmentService {

  FixFileId (params: FixFileIdParameters): Promise<any[]> {
    return smartData({
        storedProcName: 'attachment.FixFileId',
        params: params
      });
    }

  GetAttachment (params: GetAttachmentParameters): Promise<Attachment[]> {
    return smartData({
        storedProcName: 'attachment.GetAttachment',
        params: params
      });
    }

  GetAttachments (params: GetAttachmentsParameters): Promise<Attachments[]> {
    return smartData({
        storedProcName: 'attachment.GetAttachments',
        params: params
      });
    }

  GetBulkCopy (): Promise<BulkCopy[]> {
    return smartData({
        storedProcName: 'attachment.GetBulkCopy',
        params: {}
      });
    }

  GetBulkCopyItems (params: GetBulkCopyItemsParameters): Promise<BulkCopyItems[]> {
    return smartData({
        storedProcName: 'attachment.GetBulkCopyItems',
        params: params
      });
    }

  GetCategories (params: GetCategoriesParameters): Promise<Categories[]> {
    return smartData({
        storedProcName: 'attachment.GetCategories',
        params: params
      });
    }

  GetEntitiesToMap (params: GetEntitiesToMapParameters): Promise<EntitiesToMap[]> {
    return smartData({
        storedProcName: 'attachment.GetEntitiesToMap',
        params: params
      });
    }

  GetMaps (params: GetMapsParameters): Promise<Maps[]> {
    return smartData({
        storedProcName: 'attachment.GetMaps',
        params: params
      });
    }

  GetMapsForEntity (params: GetMapsForEntityParameters): Promise<MapsForEntity[]> {
    return smartData({
        storedProcName: 'attachment.GetMapsForEntity',
        params: params
      });
    }

  GetRecentAttachments (params: GetRecentAttachmentsParameters): Promise<RecentAttachments[]> {
    return smartData({
        storedProcName: 'attachment.GetRecentAttachments',
        params: params
      });
    }

  GetSites (): Promise<Sites[]> {
    return smartData({
        storedProcName: 'attachment.GetSites',
        params: {}
      });
    }

  GetStoreAttachments (params: GetStoreAttachmentsParameters): Promise<StoreAttachments[]> {
    return smartData({
        storedProcName: 'attachment.GetStoreAttachments',
        params: params
      });
    }

  GetStoreCategories (params: GetStoreCategoriesParameters): Promise<StoreCategories[]> {
    return smartData({
        storedProcName: 'attachment.GetStoreCategories',
        params: params
      });
    }

  GetStores (params: GetStoresParameters): Promise<Stores[]> {
    return smartData({
        storedProcName: 'attachment.GetStores',
        params: params
      });
    }

  GetTypes (params: GetTypesParameters): Promise<Types[]> {
    return smartData({
        storedProcName: 'attachment.GetTypes',
        params: params
      });
    }
}
